import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

const SuccessModal = ({ show, handleClose, link }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Done!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Image
            src={link}
            alt={link}
            fluid
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;